import CustomToolTip from 'apps/webapp/src/@core/components/custom-tooltip';
import { CUSTOMER_STATUS } from '@finance-ops/types';
import { RootState } from 'apps/webapp/src/store';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { useState } from 'react';

import SingleBarGraph from './SingleBarGraph';
import CustomLegend from './CustomLegend';
import CommonFooter from './CommonFooter';
import Heading from './Heading';
import Loader from 'apps/webapp/src/@core/components/loader';

const StatusOfCustomers = () => {
  const customerStatusSelector = useSelector((state: RootState) => state.dashboard.customerStatus.data);
  const loading = useSelector((state: RootState) => state.dashboard.customerStatus.loading);
  const customerStatus = customerStatusSelector.filter(each => each.text !== CUSTOMER_STATUS.CLOSED);
  const [selectedBar, setSelectedBar] = useState('');

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ pb: '32px', display: 'flex', alignItems: 'center', gap: '5px' }}>
        <Heading text='Status of Customers' />
        <CustomToolTip text='Status of Customers' />
      </Box>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box sx={{ pb: '48px' }}>
            <CustomLegend data={customerStatus} />
          </Box>
          <SingleBarGraph
            data={customerStatus.map(each => {
              return { value: each.value, text: each.text };
            })}
            setSelectedBar={setSelectedBar}
            selectedBar={selectedBar}
          />
        </>
      )}

      <Box sx={{ mt: 'auto' }}>
        <CommonFooter
          url='customers'
          filters={{ customerStatus: selectedBar }}
          displayViewMore={selectedBar ? true : false}
        />
      </Box>
    </Box>
  );
};

export default StatusOfCustomers;
