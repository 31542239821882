import { IconButton, Typography, useTheme, Divider, Box, useMediaQuery } from '@mui/material';
import CustomButtonGroup, {
  Button,
  CustomButtonGroupVariant,
} from 'apps/webapp/src/@core/components/custom-buttom-group';
import { PERMISSION_SUBJECT, WebSocketMessageType, PERMISSION_ACTION, UI_MODE } from '@finance-ops/types';
import { AbilityContext } from 'apps/webapp/src/layouts/components/acl/Can';
import { sendMessage } from 'apps/webapp/src/websocket/WebSocketClient';
import { setUIMode } from 'apps/webapp/src/store/dashboard';
import { notify } from 'apps/webapp/src/notification/Notifications';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { useAuth } from 'apps/webapp/src/hooks/useAuth';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import ClientList from '../../ClientList';

const Header = ({ uiMode }: { uiMode: UI_MODE }) => {
  const ability = useContext(AbilityContext);
  const { user } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  const setNewUIMode = (uiMode: UI_MODE) => {
    dispatch(setUIMode(uiMode));
  };

  const buttonsList = [
    { label: UI_MODE.COPILOT, key: UI_MODE.COPILOT },
    { label: UI_MODE.AUTOPILOT, key: UI_MODE.AUTOPILOT },
  ];

  const typeButtons = user?.role?.isInternal ? [{ label: UI_MODE.ALL, key: UI_MODE.ALL }, ...buttonsList] : buttonsList;

  const runAgg = () => {
    sendMessage(WebSocketMessageType.GET_DASHBOARD_AGG);
    notify({ msg: { content: 'Dashboard aggregations triggered successfully' } });
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          ...(isMobile && {
            justifyContent: 'center',
            gap: '15px',
          }),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '5px',
            alignItems: 'center',
            flexWrap: 'wrap',
            ...(isMobile && {
              flexDirection: 'column',
              justifyContent: 'center',
            }),
          }}
        >
          <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
            <Typography
              sx={{
                fontSize: '24px',
                color: theme.palette.customColors.headingPrimary,
                fontWeight: '700',
              }}
            >
              Hello
            </Typography>
            <Typography sx={{ fontSize: '24px', color: theme.palette.primary.main, fontWeight: '700' }}>
              {user?.firstName || ''}
            </Typography>
            <Typography
              sx={{
                fontSize: '24px',
                color: theme.palette.customColors.headingPrimary,
                fontWeight: '700',
                ml: '-4px',
              }}
            >
              !
            </Typography>
          </Box>
          <Box
            sx={{
              ml: isMobile ? 0 : 4,
              ...(isMobile && {
                '& .client-list-text-field': {
                  width: '200px !important',
                },
                '& .MuiAutocomplete-root': {
                  ml: '0 !important',
                },
              }),
            }}
          >
            <ClientList />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {ability?.can(PERMISSION_ACTION.READ, PERMISSION_SUBJECT.ONLY_SUPER_ADMIN) ? (
            <IconButton onClick={runAgg} sx={{ mr: 5, color: 'primary.main' }}>
              <PlayCircleIcon />
            </IconButton>
          ) : null}

          <CustomButtonGroup
            buttons={typeButtons as Button[]}
            onSelection={value => {
              setNewUIMode(value as UI_MODE);
            }}
            value={uiMode}
            variant={CustomButtonGroupVariant.primary}
          />
        </Box>
      </Box>
      <Divider sx={{ mt: 2, color: `${theme.palette.grey[400]}`, border: '0.7px solid' }} />
    </>
  );
};

export default Header;
