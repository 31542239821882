import CustomToolTip from 'apps/webapp/src/@core/components/custom-tooltip';
import { RootState } from 'apps/webapp/src/store';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import SingleBarGraph from './SingleBarGraph';
import CustomLegend from './CustomLegend';
import CommonFooter from './CommonFooter';
import Heading from './Heading';
import Loader from 'apps/webapp/src/@core/components/loader';
import { useEffect, useState } from 'react';

const PaymentsGraph = () => {
  const { loading, data } = useSelector((state: RootState) => state.dashboard.paymentStatus);
  const [displayData, setDisplayData] = useState<any[]>([]);
  const [selectedBar, setSelectedBar] = useState('');

  useEffect(() => {
    if (data) {
      const displayData = data.map(each => {
        return { text: each._id as string, value: each.count };
      });
      setDisplayData(displayData.sort((a, b) => b.value - a.value));
    }
  }, [data]);

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ pb: '32px', display: 'flex', alignItems: 'center', gap: '5px' }}>
        <Heading text='Payments' />
        <CustomToolTip text='Payments' />
      </Box>
      {loading ? (
        <Loader height={'200px'} />
      ) : (
        <>
          <Box sx={{ pb: '48px' }}>
            <CustomLegend data={displayData} />
          </Box>
          <SingleBarGraph data={displayData} setSelectedBar={setSelectedBar} selectedBar={selectedBar} />
        </>
      )}

      <Box>
        <Box sx={{ marginTop: 'auto' }}>
          <CommonFooter
            url='payments'
            filters={{ page: 'transactions', 'filters.paymentStatus': selectedBar }}
            displayViewMore={selectedBar ? true : false}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentsGraph;
