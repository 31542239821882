import { Box, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { addDays, differenceInDays } from 'date-fns';
import { formatUSD } from 'apps/webapp/src/@core/utils/format';
import { RootState } from 'apps/webapp/src/store';
import SyncIcon from '@mui/icons-material/Sync';
import { useSelector } from 'react-redux';

import CollectionPerformanceGraph from './graphs/CollectionPerformanceGraph';
import CustomDatePicker from './CustomDatePicker';
import CommonFooter from './CommonFooter';
import ChangeChip from './ChangeChip';
import Heading from './Heading';
import Loader from 'apps/webapp/src/@core/components/loader';
import { DownSamplingTimeFrame, GraphDataPoint } from '@finance-ops/types';

interface DateRanges {
  main: [Date | null, Date | null];
  compare?: [Date | null, Date | null];
}

const CollectionPerformance = ({
  dateRanges,
  setDateRanges,
  getCollectionsGraph,
  downSamplingSize,
  setDownSamplingSize,
}: {
  dateRanges: DateRanges;
  setDateRanges: Dispatch<SetStateAction<DateRanges>>;
  getCollectionsGraph: () => void;
  downSamplingSize: DownSamplingTimeFrame;
  setDownSamplingSize: Dispatch<SetStateAction<DownSamplingTimeFrame>>;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [data, setData] = useState<{ name: string; data: any[] }[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [totalPrev, setTotalPrev] = useState<number>(0);
  const [difference, setDifference] = useState<number>(0);

  const collections: Array<{ name: string; data: Array<{ x: Date | string; y: number }> }> = useSelector(
    (state: RootState) => state.dashboard.collections.data,
  );
  const loading: boolean = useSelector((state: RootState) => state.dashboard.collections.loading);

  const ensureConsistentDataPoints = (
    collectionsData: GraphDataPoint[],
    prevCollectionsData: GraphDataPoint[],
    predictedCollectionsData: GraphDataPoint[],
  ): [GraphDataPoint[], GraphDataPoint[], GraphDataPoint[]] => {
    const convertXToDate = (dp: GraphDataPoint) => ({
      ...dp,
      x: dp.x instanceof Date ? dp.x : new Date(dp.x),
    });
    const allXValues = new Set([
      ...collectionsData.map(convertXToDate).map(dp => dp.x),
      ...prevCollectionsData.map(convertXToDate).map(dp => dp.x),
      ...predictedCollectionsData.map(convertXToDate).map(dp => dp.x),
    ]);

    const sortedXValues = Array.from(allXValues).sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));

    const updatedCollectionsData = Array.from(sortedXValues).map(x => {
      return collectionsData.find(dp => dp.x === x) || { x, y: 0 };
    });

    const updatedPrevCollectionsData = Array.from(sortedXValues).map(x => {
      return prevCollectionsData.find(dp => dp.x === x) || { x, y: 0 };
    });

    const updatedPredictedCollectionsData = Array.from(sortedXValues).map(x => {
      return predictedCollectionsData.find(dp => dp.x === x) || { x, y: 0 };
    });

    // Step 4: Return the updated arrays
    return [updatedCollectionsData, updatedPrevCollectionsData, updatedPredictedCollectionsData];
  };

  useEffect(() => {
    const collectionsData =
      Object.values(collections)
        .find(each => each.name === 'Collections')
        ?.data?.map(each => {
          return { x: new Date(each.x), y: each.y };
        }) ?? [];
    const predictedData =
      Object.values(collections)
        .find(each => each.name === 'Predicted')
        ?.data?.map(each => {
          return {
            x: new Date(each.x),
            y: each.y,
          };
        }) ?? [];
    const prevCollectionsOg = Object.values(collections).find(each => each.name === 'Previous Collections')?.data ?? [];

    let filterDiff =
      dateRanges.main.length > 1
        ? differenceInDays(new Date(dateRanges.main[1] ?? new Date()), new Date(dateRanges.main[0] ?? new Date()))
        : 1;

    if (filterDiff < 1) filterDiff = 1;

    setDifference(filterDiff);

    const prevCollectionsData = prevCollectionsOg.map(each => ({
      x: addDays(new Date(each.x), filterDiff),
      y: each.y,
    }));

    setTotal(collectionsData.reduce((acc, each) => acc + each.y, 0));
    setTotalPrev(prevCollectionsData.reduce((acc, each) => acc + each.y, 0));

    // const [collectionsDataMerged, prevCollectionsDataMerged, predictedCollectionsDataMerged] =
    //   ensureConsistentDataPoints(collectionsData, prevCollectionsData, predictedData);

    setData([
      {
        name: 'Collections',
        data: collectionsData,
      },
      { name: 'Predicted', data: predictedData },
      {
        name: 'Previous Collections',
        data: prevCollectionsData,
      },
    ]);
  }, [collections, dateRanges]);

  return (
    <Box sx={{ pt: '20px', display: 'flex', flexDirection: 'column' }}>
      {!isMobile && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Heading text='Collection Performance' />
            <Box sx={{ pl: 2, width: '10%' }}>
              <ChangeChip old={totalPrev} now={total} />
            </Box>
            <IconButton onClick={getCollectionsGraph} sx={{ ml: 5 }}>
              <SyncIcon sx={{ color: 'primary.main' }} />
            </IconButton>
          </Box>
          <Box>
            <CustomDatePicker
              showComparison={true}
              setDateRanges={setDateRanges}
              dateRanges={dateRanges}
              downSamplingSize={downSamplingSize}
              setDownSamplingSize={setDownSamplingSize}
            />
          </Box>
        </Box>
      )}
      {!isMobile && (
        <Typography sx={{ color: theme.palette.customColors.headingPrimary, fontSize: '20px' }}>
          {formatUSD(total)}
        </Typography>
      )}
      {loading ? <Loader height='230px' /> : <CollectionPerformanceGraph data={data} difference={difference} />}
      <CommonFooter url='payments' filters={{ page: 'transactions', paymentsFrom: difference.toString() }} />
    </Box>
  );
};

export default CollectionPerformance;
