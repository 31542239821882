import { CUSTOMER_STATUS, DashboardGraphTypes, WebSocketMessageType } from '@finance-ops/types';
import CustomToolTip from 'apps/webapp/src/@core/components/custom-tooltip';
import { sendMessage } from 'apps/webapp/src/websocket/WebSocketClient';
import { RootState } from 'apps/webapp/src/store';
import { addDays, startOfDay } from 'date-fns';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';

import DelinquencyPerformanceGraph from './graphs/DelinquencyPerformanceGraph';
import CustomDatePickerDelinquencyGraph from './CustomDatePickerDelinquencyGraph';
import CommonFooter from './CommonFooter';
import Heading from './Heading';
import { setGraphLoading } from 'apps/webapp/src/store/dashboard';
import Loader from 'apps/webapp/src/@core/components/loader';
import useLocalStorageDashboardState from 'apps/webapp/src/hooks/dashboard';
import { convertDates } from 'apps/webapp/src/utils/date-utils';

interface DateRanges {
  main: [Date | null, Date | null];
  compare?: [Date | null, Date | null];
}

const DelinquentRate = ({ selectedClientId }: { selectedClientId?: string | null }) => {
  const dispatch = useDispatch();
  const { getLocalStorageDashboardState, setLocalStorageDashboardState } = useLocalStorageDashboardState();
  const [dateRanges, setDateRanges] = useState<DateRanges>(() => {
    const localStorageDashboardState = getLocalStorageDashboardState();

    if (localStorageDashboardState.delinquentRateDateRanges) {
      return convertDates(localStorageDashboardState.delinquentRateDateRanges);
    }
    return { main: [addDays(new Date(), -6), new Date()] };
  });
  const delinquency = useSelector((state: RootState) => state.dashboard.delinquency.data);
  const loader = useSelector((state: RootState) => state.dashboard.delinquency.loading);
  const uiMode = useSelector((state: RootState) => state.dashboard.uiMode);

  const prevRecord = { x: startOfDay(dateRanges.main[0] ?? new Date()), y: 0 };

  const delinquencyData = [prevRecord, ...delinquency];

  useEffect(() => {
    dispatch(setGraphLoading(DashboardGraphTypes.DELINQUENCY_GRAPH));
    setLocalStorageDashboardState({ delinquentRateDateRanges: dateRanges });
    sendMessage(WebSocketMessageType.GET_DASHBOARD_GRAPH, {
      uiMode,
      clientIdDropdown: selectedClientId,
      graphType: DashboardGraphTypes.DELINQUENCY_GRAPH,
      filters: dateRanges.main,
    });
  }, [dateRanges, uiMode, selectedClientId]);

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <Heading text='Delinquent Rates' />
          <CustomToolTip text='Delinquent Rates' />
        </Box>
        <Box>
          <CustomDatePickerDelinquencyGraph
            showComparison={false}
            dateRanges={dateRanges}
            setDateRanges={setDateRanges}
          />
        </Box>
      </Box>
      {loader ? <Loader /> : <DelinquencyPerformanceGraph delinquency={delinquencyData} />}

      <Box sx={{ mt: 'auto' }}>
        <CommonFooter url='customers' filters={{ customerStatus: CUSTOMER_STATUS.CLOSED }} />
      </Box>
    </Box>
  );
};

export default DelinquentRate;
