import { Dialog, DialogActions, DialogContent, Button, Grid, IconButton, useTheme } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import React, { Dispatch, ReactElement, useEffect, useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { styled } from '@mui/material/styles';

import CollectabilityMatrix from './CollectMatrix';
import ActionCenterTickets from './ActionCenterTickets';
import CustomerEngagement from './CustomerEngagement';
import TopPaymentsMethod from './TopPaymentsMethod';
import StatusOfCustomers from './StatusOfCustomers';
import NumberOfInvoices from './NumberOfInvoices';
import RecentPayments from './RecentPayments';
import DelinquentRate from './DelinquentRate';
import AgingSummary from './AgingSummary';
import PaymentsGraph from './Payments';
import TopDebtor from './TopDebtor';
import { useSelector } from 'react-redux';
import { RootState } from 'apps/webapp/src/store';

interface ComponentPreviewProps {
  component: ReactElement;
  name: string;
  visible: boolean;
  toggleVisibility: (name: string) => void;
}

interface VisibilitySettingsModalProps {
  open: boolean;
  onClose: () => void;
  visibleComponents: { [key: string]: boolean };
  setVisibleComponents: Dispatch<React.SetStateAction<any>>;
  selectedClientId?: string;
}

const PreviewContainer = styled(Grid)(({ theme }) => ({
  position: 'relative',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
}));

const ToggleVisibilityButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  color: theme.palette.primary.main,
}));

const getWidth = (name: string) => {
  if (
    name === 'agingSummary' ||
    name === 'topDebtor' ||
    name === 'recentPayments' ||
    name === 'delinquentRate' ||
    name === 'numberOfInvoices' ||
    name === 'actionCenterCustomers'
  ) {
    return 7;
  }
  return 4;
};

const ComponentPreview = ({ component, name, visible, toggleVisibility }: ComponentPreviewProps) => (
  <PreviewContainer item xs={12} sm={12} md={getWidth(name)}>
    {component}
    <ToggleVisibilityButton onClick={() => toggleVisibility(name)}>
      {visible ? <CheckCircleOutlineIcon /> : <AddCircleOutlineIcon />}
    </ToggleVisibilityButton>
  </PreviewContainer>
);

const VisibilitySettingsModal = ({
  open,
  onClose,
  visibleComponents,
  setVisibleComponents,
  selectedClientId,
}: VisibilitySettingsModalProps) => {
  const theme = useTheme();
  const agingSummary = useSelector((state: RootState) => state.dashboard.aggregation.agingSummary);
  const loading = useSelector((state: RootState) => state.dashboard.loading);
  const [stagedVisibleComponents, setStagedVisibleComponents] = useState({ ...visibleComponents });
  const toggleVisibility = (component: any) => {
    setStagedVisibleComponents(prev => ({
      ...prev,
      [component]: !prev[component],
    }));
  };

  useEffect(() => {
    setStagedVisibleComponents(visibleComponents);
  }, [visibleComponents]);

  const handleApply = () => {
    setVisibleComponents(stagedVisibleComponents);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='lg'>
      <DialogContent sx={{ overflowX: 'hidden' }}>
        <Grid container spacing={5} gap={'30px'} sx={{ margin: 'auto' }}>
          <ComponentPreview
            component={<AgingSummary agingSummary={agingSummary} loading={loading} />}
            name='agingSummary'
            visible={stagedVisibleComponents.agingSummary}
            toggleVisibility={toggleVisibility}
          />
          <ComponentPreview
            component={<CollectabilityMatrix />}
            name='collectabilityMatrix'
            visible={stagedVisibleComponents.collectabilityMatrix}
            toggleVisibility={toggleVisibility}
          />

          <ComponentPreview
            component={<TopDebtor />}
            name='topDebtor'
            visible={stagedVisibleComponents.topDebtor}
            toggleVisibility={toggleVisibility}
          />
          <ComponentPreview
            component={<CustomerEngagement />}
            name='customerEngagement'
            visible={stagedVisibleComponents.customerEngagement}
            toggleVisibility={toggleVisibility}
          />

          <ComponentPreview
            component={<RecentPayments />}
            name='recentPayments'
            visible={stagedVisibleComponents.recentPayments}
            toggleVisibility={toggleVisibility}
          />
          <ComponentPreview
            component={<PaymentsGraph />}
            name='paymentsGraph'
            visible={stagedVisibleComponents.paymentsGraph}
            toggleVisibility={toggleVisibility}
          />

          <ComponentPreview
            component={<DelinquentRate selectedClientId={selectedClientId} />}
            name='delinquentRate'
            visible={stagedVisibleComponents.delinquentRate}
            toggleVisibility={toggleVisibility}
          />
          <ComponentPreview
            component={<TopPaymentsMethod />}
            name='topPaymentsMethod'
            visible={stagedVisibleComponents.topPaymentsMethod}
            toggleVisibility={toggleVisibility}
          />
          <ComponentPreview
            component={<NumberOfInvoices />}
            name='numberOfInvoices'
            visible={stagedVisibleComponents.numberOfInvoices}
            toggleVisibility={toggleVisibility}
          />
          <ComponentPreview
            component={<ActionCenterTickets />}
            name='actionCenterCustomers'
            visible={stagedVisibleComponents.actionCenterCustomers}
            toggleVisibility={toggleVisibility}
          />
          <ComponentPreview
            component={<StatusOfCustomers />}
            name='statusOfCustomers'
            visible={stagedVisibleComponents.statusOfCustomers}
            toggleVisibility={toggleVisibility}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          sx={{
            border: `1px solid ${theme.palette.text.primary}`,
            color: theme.palette.text.primary,
            py: '2px',
            px: '4px',
            '&:hover': {
              background: theme.palette.common.black,
              color: theme.palette.common.white,
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleApply}
          sx={{
            color: theme.palette.common.white,
            py: '3px',
            px: '4px',
            background: theme.palette.primary.main,
            '&:hover': {
              background: theme.palette.common.black,
            },
          }}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VisibilitySettingsModal;
