import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, useTheme, Box } from '@mui/material';
import CustomToolTip from 'apps/webapp/src/@core/components/custom-tooltip';
import { RootState } from 'apps/webapp/src/store';
import { TopDebtor } from '@finance-ops/types';
import { useSelector } from 'react-redux';
import Loader from 'apps/webapp/src/@core/components/loader';

import Heading from './Heading';
import { useAuth } from 'apps/webapp/src/hooks/useAuth';
import { useRouter } from 'next/router';
import { taskModeFromUiMode } from '../utils/taskModeFromUiMode';

const TopDebtors = () => {
  const { user } = useAuth();

  const theme = useTheme();
  const router = useRouter();
  const topDebtors = useSelector((state: RootState) => state.dashboard.topDebtors);
  const uiMode = useSelector((state: RootState) => state.dashboard.uiMode);
  const isInternal = user?.role?.isInternal;

  const handleClick = (sourceId: string) => {
    const taskMode = taskModeFromUiMode(isInternal, uiMode);

    router.push({
      pathname: `/customers`,
      query: { sourceId: sourceId, mode: taskMode },
    });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ pb: '16px', display: 'flex', alignItems: 'center', gap: '5px' }}>
        <Heading text='Top Debtors' />
        <CustomToolTip text='Top Debtors' />
      </Box>
      {topDebtors.loading ? (
        <Loader />
      ) : (
        <TableContainer component={Paper}>
          <Table
            sx={{
              '& .MuiTableRow-root': {
                borderBottom: `1px solid ${theme.palette.grey[300]}`,
              },
              '& .MuiTableCell-root': { py: '9px !important' },
              '& th.MuiTableCell-head': {
                background: 'transparent',
                py: '12px !important',
              },
            }}
            aria-label='simple table'
          >
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align='right'>Delinquent Days</TableCell>
                <TableCell align='right'>Amount ($)</TableCell>
                <TableCell>Payment Plan</TableCell>
                {isInternal && <TableCell>Agent Assigned</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {topDebtors.data.map((debtor: TopDebtor) => (
                <TableRow
                  key={debtor.customerId}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'lightgray',
                      cursor: 'pointer',
                    },
                    '&:hover .MuiTableCell-root': {
                      fontWeight: 500,
                    },
                  }}
                  onClick={() => handleClick(debtor.referenceId)}
                >
                  <TableCell scope='row'>{debtor.customerName}</TableCell>
                  <TableCell align='right'>{debtor.delinquentDays}</TableCell>
                  <TableCell align='right'>{debtor.amount}</TableCell>
                  <TableCell>{debtor.hasPaymentPlan}</TableCell>
                  {isInternal && <TableCell>{debtor.assignedAgentName}</TableCell>}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default TopDebtors;
