import ReactApexCharts from 'apps/webapp/src/@core/components/react-apexcharts';
import CustomToolTip from 'apps/webapp/src/@core/components/custom-tooltip';
import { RootState } from 'apps/webapp/src/store';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import CommonFooter from './CommonFooter';
import CustomLegend from './CustomLegend';
import Heading from './Heading';
import { INVOICE_STATUS } from '@finance-ops/types';
import { useState } from 'react';

import Loader from 'apps/webapp/src/@core/components/loader';

const NumberOfInvoices = () => {
  const invoices = useSelector((state: RootState) => state.dashboard.aggregation.invoices);
  const loading = useSelector((state: RootState) => state.dashboard.loading);

  const [selectedInvoiceStatus, setSelectedInvoiceStatus] = useState<INVOICE_STATUS | null>();

  const handleSetSelectedInvoiceStatus = (status: INVOICE_STATUS) => {
    setSelectedInvoiceStatus(prev => (prev === status ? null : status));
  };
  const pieLabels = [INVOICE_STATUS.UNPAID, INVOICE_STATUS.PARTIALLY_PAID];
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alingItems: 'center',
        justifyContent: 'space-between',
        minHeight: '100%',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        <Heading text='Number of invoices' />
        <CustomToolTip text='Number of invoices' />
      </Box>
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <ReactApexCharts
            css={{
              '& .apexcharts-legend': {
                alignItems: 'baseline',
                display: 'none',
              },
              maxWidth: '60%',
            }}
            align='center'
            options={{
              chart: {
                type: 'donut',
                offsetX: -30,
                events: {
                  dataPointSelection(e, chart, options) {
                    handleSetSelectedInvoiceStatus(pieLabels[options.dataPointIndex]);
                  },
                },
              },
              stroke: {
                show: true,
                width: 6,
              },
              labels: pieLabels,
              colors: ['#6461F3', '#C0AAFF', '#263238', '#CFD8DC'],
              dataLabels: {
                enabled: false,
              },
              plotOptions: {
                pie: {
                  expandOnClick: false,
                  donut: {
                    size: '70%',
                    labels: {
                      show: true,
                      name: {
                        show: true,
                        color: '#CCC',
                        fontSize: '0.7rem',
                        offsetY: -5,
                      },
                      value: {
                        show: true,
                        fontSize: '0.92rem',
                        fontWeight: '600',
                        offsetY: 3,
                      },
                      total: {
                        show: true,
                      },
                    },
                    background: '#FFF',
                  },
                },
              },
              tooltip: {
                enabled: false,
              },
              legend: {
                show: true,
                fontSize: '10 px',
                markers: {
                  width: 8,
                  height: 8,
                },
                itemMargin: {
                  horizontal: 30,
                  vertical: 5,
                },
                offsetY: 40,
                offsetX: 35,
              },
            }}
            series={[invoices.open, invoices.partiallyPaid ?? 0]}
            type='donut'
          />
          <CustomLegend
            data={[
              { text: 'Unpaid', value: invoices.open ?? 0 },
              { text: 'Partially Paid', value: invoices.partiallyPaid ?? 0 },
            ]}
            isVertical={true}
          />
        </Box>
      )}
      <Box>
        <CommonFooter
          url='all-invoices'
          filters={{
            invoiceStatus: selectedInvoiceStatus ?? '',
          }}
          displayViewMore={selectedInvoiceStatus ? true : false}
        />
      </Box>
    </Box>
  );
};

export default NumberOfInvoices;
