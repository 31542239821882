import ChangeChip from 'apps/webapp/src/views/dashboard/components/ChangeChip';
import IconNames from 'apps/webapp/src/constants/IconNames';
import { Box, Typography, useTheme } from '@mui/material';
import { useAuth } from 'apps/webapp/src/hooks/useAuth';
import { INVOICE_STATUS } from '@finance-ops/types';

import CustomIcon from '../custom-icons';
import { formatCurrency } from '../../utils/format';
import CommonFooter from 'apps/webapp/src/views/dashboard/components/CommonFooter';

interface CustomSummaryItemInterface {
  heading: string;
  currentValue: number;
  previousValue?: number;
  minWidth?: string;
  type?: 'currency' | 'percentage';
  url?: string;
}
const CustomSummaryItem = ({
  heading,
  currentValue,
  previousValue = 0,
  minWidth = '240px',
  url = '',
  type,
}: CustomSummaryItemInterface) => {
  const theme = useTheme();
  const { user } = useAuth();

  const getFilters = (): { [key in string]: string } => {
    switch (heading) {
      case 'Open amount':
        return { invoiceStatus: INVOICE_STATUS.UNPAID };
      case 'Collected amount':
        return { page: 'transactions' };
      default:
        return {};
    }
  };

  const displayValue = (value: number) => {
    switch (type) {
      case 'currency':
        return formatCurrency(value);
      case 'percentage':
        return `${value.toFixed(2)}%`;
      default:
        return value;
    }
  };

  return (
    <Box sx={{ minWidth: minWidth, pl: 2, borderLeft: '2px solid darkgray' }}>
      <Box sx={{ alignItems: 'center' }}>
        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <Typography sx={{ pb: 0, fontSize: '1.2rem', fontWeight: 600 }}>{heading}</Typography>
          {!!currentValue && user?.role?.isInternal && <ChangeChip old={previousValue} now={currentValue} />}
        </Box>
      </Box>
      {!((!user?.role?.isInternal && heading === 'CEI') || (!user?.role?.isInternal && heading === 'Recovery rate')) ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {previousValue ? (
            <>
              <Typography
                sx={{
                  color: theme.palette.customColors.headingPrimary,
                  fontSize: '16px',
                }}
              >
                {displayValue(previousValue)}
              </Typography>
              <CustomIcon name={IconNames.rightArrow} />
            </>
          ) : null}
          <Typography sx={{ pt: 0, px: 1, fontSize: '1.8rem', fontWeight: 500 }}>
            {displayValue(currentValue)}
          </Typography>
        </Box>
      ) : (
        '-'
      )}

      {url ? <CommonFooter url={url} filters={{ ...getFilters() }} displayLastUpdated={false} /> : null}
    </Box>
  );
};

export default CustomSummaryItem;
