import CustomToolTip from 'apps/webapp/src/@core/components/custom-tooltip';
import { RootState } from 'apps/webapp/src/store';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import SingleBarGraph from './SingleBarGraph';
import CustomLegend from './CustomLegend';
import CommonFooter from './CommonFooter';
import Heading from './Heading';
import Loader from 'apps/webapp/src/@core/components/loader';
import { useEffect, useState } from 'react';
import { PaymentMethod } from '@finance-ops/types';

const TopPaymentsMethod = () => {
  const [displayData, setDisplayData] = useState<any[]>([]);
  const [selectedBar, setSelectedBar] = useState('');

  const { loading, data } = useSelector((state: RootState) => state.dashboard.paymentTypes);

  useEffect(() => {
    if (data) {
      setDisplayData(
        data
          .map(each => {
            return {
              text: each._id ?? PaymentMethod.OTHERS,
              value: each.count,
            };
          })
          .sort((a, b) => b.value - a.value),
      );
    }
  }, [data]);

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ pb: '32px', display: 'flex', alignItems: 'center', gap: '5px' }}>
        <Heading text='Top Payment Methods' />
        <CustomToolTip text='Top Payment Methods' />
      </Box>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box sx={{ pb: '48px' }}>
            <CustomLegend data={displayData} />
          </Box>
          <SingleBarGraph data={displayData} setSelectedBar={setSelectedBar} selectedBar={selectedBar} />
        </>
      )}
      <Box sx={{ mt: 'auto' }}>
        <CommonFooter
          url='payments'
          filters={{ page: 'transactions', 'filters.paymentMethod': selectedBar }}
          displayViewMore={selectedBar ? true : false}
        />
      </Box>
    </Box>
  );
};

export default TopPaymentsMethod;
