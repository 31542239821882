import CustomToolTip from 'apps/webapp/src/@core/components/custom-tooltip';
import { RootState } from 'apps/webapp/src/store';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

import SingleBarGraph from './SingleBarGraph';
import CustomLegend from './CustomLegend';
import CommonFooter from './CommonFooter';
import Heading from './Heading';

import Loader from 'apps/webapp/src/@core/components/loader';

const ActionCenterTickets = () => {
  const actionCenter = useSelector((state: RootState) => state.dashboard.actionCenter.data);
  const loading = useSelector((state: RootState) => state.dashboard.actionCenter.loading);

  const [selectedBar, setSelectedBar] = useState('');
  const [displayData, setDisplayData] = useState<Array<{ text: string; value: number }>>([]);

  useEffect(() => {
    setDisplayData(actionCenter.map(item => ({ text: item._id, value: item.count })).sort((a, b) => b.value - a.value));
  }, [actionCenter]);

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ pb: '32px', display: 'flex', alignItems: 'center', gap: '5px' }}>
        <Heading text='Resolution Center Tickets' />
        <CustomToolTip text='Resolution Center Tickets' />
      </Box>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box sx={{ pb: '48px' }}>
            <CustomLegend data={displayData} />
          </Box>
          <SingleBarGraph
            data={displayData}
            setSelectedBar={(newValue: string) =>
              setSelectedBar(old => {
                return newValue === old ? '' : newValue;
              })
            }
            selectedBar={selectedBar}
          />
        </>
      )}
      <Box sx={{ mt: 'auto' }}>
        <CommonFooter
          url='resolution-center'
          filters={{ status: selectedBar }}
          displayViewMore={selectedBar ? true : false}
        />
      </Box>
    </Box>
  );
};

export default ActionCenterTickets;
