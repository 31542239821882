import { formatTimestamp, formatUSD } from 'apps/webapp/src/@core/utils/format';
import ReactApexCharts from 'apps/webapp/src/@core/components/react-apexcharts';
import { differenceInDays, format, isSameDay, subDays } from 'date-fns';
import { Box, useTheme } from '@mui/material';
import { useEffect, useRef } from 'react';

const CollectionPerformanceGraph = ({
  data,
  difference = 4,
}: {
  data: { name: string; data: { x: Date; y: number }[] }[];
  difference: number;
}) => {
  const theme = useTheme();
  const diffRef = useRef(difference);

  useEffect(() => {
    diffRef.current = difference;
  }, [difference]);

  return (
    <Box style={{ width: '100%', height: '230px' }}>
      <ReactApexCharts
        css={{
          '& .apexcharts-svg': {
            backgroundColor: `${theme.palette.background.paper} !important`,
          },
          '& .apexcharts-toolbar': {
            display: 'none',
          },
          '& .apexcharts-xaxistooltip': {
            display: 'none !important',
          },
          '& .apexcharts-legend': {
            left: 'auto !important',
          },
          '& .apexcharts-legend-marker': {
            borderRadius: '4px !important',
          },
        }}
        series={data.map((each, idx) => ({
          name: each.name,
          data: each.data,
          color: idx === 0 ? theme.palette.primary.main : idx === 1 ? theme.palette.customColors.green : '#C5C5C5',
        }))}
        options={{
          chart: {
            id: 'basic-bar',
            type: 'line',
          },
          xaxis: {
            type: 'datetime',
            labels: {
              formatter(value, timestamp) {
                const date = new Date(value);
                const today = new Date();
                if (isSameDay(date, today)) {
                  return format(date, 'hh:mm a');
                } else if (differenceInDays(today, date) <= 7) {
                  return format(date, 'MMM dd, hh a');
                } else {
                  return format(date, 'MMM dd, yyyy');
                }
              },
            },
          },
          yaxis: {
            labels: {
              formatter: (value: number) => formatUSD(value),
            },
          },
          stroke: {
            curve: 'smooth',
            width: 3,
            colors: [theme.palette.primary.main, '#E25CC4', '#EBB447', theme.palette.customColors.green],
          },
          fill: {
            type: 'solid',
          },
          grid: {
            show: true,
            borderColor: theme.palette.grey[300],
            strokeDashArray: 10,
            position: 'back',
            xaxis: {
              lines: {
                show: false,
              },
            },
            yaxis: {
              lines: {
                show: true,
              },
            },
          },
          legend: {
            position: 'top',
          },
          tooltip: {
            shared: true,
            intersect: false,
            style: {
              fontSize: '10px',
            },
            x: {
              show: false,
            },
            y: {
              formatter: (
                value: number,
                {
                  series,
                  seriesIndex,
                  dataPointIndex,
                  w,
                }: { series: any[]; seriesIndex: number; dataPointIndex: number; w: any },
              ) => {
                let xValue = w.globals.seriesX[seriesIndex][dataPointIndex];
                const seriesName = w.globals.seriesNames[seriesIndex];

                if (seriesName === 'Previous Collections') {
                  xValue = subDays(xValue, diffRef.current);
                }

                return `<div style="display: flex; justify-content: space-between; min-width: 10em;">
                <div>${formatTimestamp(xValue)}</div>
                <div>${value ? formatUSD(value) : '$0'}</div>
              </div>`;
              },
              title: {
                formatter: (seriesName: string) => '',
              },
            },
          },
        }}
        type='line'
        width='100%'
        height='100%'
      />
    </Box>
  );
};

export default CollectionPerformanceGraph;
