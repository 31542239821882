import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, useTheme, Box } from '@mui/material';
import CustomToolTip from 'apps/webapp/src/@core/components/custom-tooltip';
import { formatDateAndTime } from 'apps/webapp/src/@core/utils/format';
import { RootState } from 'apps/webapp/src/store';
import { useSelector } from 'react-redux';

import Heading from './Heading';
import Loader from 'apps/webapp/src/@core/components/loader';
import { useAuth } from 'apps/webapp/src/hooks/useAuth';
import { useRouter } from 'next/router';
import { taskModeFromUiMode } from '../utils/taskModeFromUiMode';

const RecentPayments = () => {
  const { user } = useAuth();
  const theme = useTheme();
  const router = useRouter();
  const { loading, data } = useSelector((state: RootState) => state.dashboard.recentPayments);
  const uiMode = useSelector((state: RootState) => state.dashboard.uiMode);

  const isInternal = user?.role?.isInternal;

  const handleClick = (transactionId: string) => {
    const taskMode = taskModeFromUiMode(isInternal, uiMode);

    let filterPayload: any = { transactionId: transactionId, page: 'transactions' };

    if (isInternal) {
      filterPayload = { ...filterPayload, mode: taskMode };
    }

    router.push({
      pathname: `/payments`,
      query: filterPayload,
    });
  };

  return (
    <Box>
      <Box sx={{ pb: '16px', display: 'flex', alignItems: 'center', gap: '5px' }}>
        <Heading text='Recent payments' />
        <CustomToolTip text='Recent payments' />
      </Box>
      {loading ? (
        <Loader height={'200px'} />
      ) : (
        <TableContainer component={Paper}>
          <Table
            sx={{
              minWidth: 650,
              '& .MuiTableRow-root': {
                borderBottom: `1px solid ${theme.palette.grey[300]}`,
              },
              '& .MuiTableCell-root': { py: '9px !important' },
              '& th.MuiTableCell-head': {
                background: 'transparent',
                py: '12px !important',
              },
            }}
            aria-label='simple table'
          >
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align='right'>Amount ($)</TableCell>
                <TableCell align='right'>Transaction ID</TableCell>
                {isInternal && <TableCell>Agent Assigned</TableCell>}
                <TableCell>Transaction Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((payee, index) => (
                <TableRow
                  key={payee.transactionId || index}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'lightgray',
                      cursor: 'pointer',
                    },
                    '&:hover .MuiTableCell-root': {
                      fontWeight: 500,
                    },
                  }}
                  onClick={() => handleClick(payee.transactionId)}
                >
                  <TableCell scope='row'>{payee.customerName}</TableCell>
                  <TableCell align='right'>{payee.amount}</TableCell>
                  <TableCell align='right' width={300}>
                    {payee.transactionId}
                  </TableCell>
                  {isInternal && <TableCell>{payee.assignedAgentName}</TableCell>}
                  <TableCell>{formatDateAndTime(payee.transactionDate)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {/* <CommonFooter url='payments' filters={{ page: 'transactions' }} /> */}
    </Box>
  );
};

export default RecentPayments;
