import { Box, Button, Typography, useTheme } from '@mui/material';
import CustomIcon from 'apps/webapp/src/@core/components/custom-icons';
import IconNames from 'apps/webapp/src/constants/IconNames';

const OverviewSection = ({ onAddClick }: { onAddClick: () => void }) => {
  const theme = useTheme();

  const buttonStyle = {
    border: '1px solid #888',
    padding: '2px 6px',
    borderRadius: '2px',
    color: theme.palette.primary.dark,
    display: 'flex',
    gap: '3px',
    alignItems: 'center',
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
        <Typography sx={{ color: theme.palette.customColors.headingPrimary, fontSize: '18px', fontWeight: 700 }}>
          Your Overview
        </Typography>
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <Button
            onClick={onAddClick}
            sx={{
              ...buttonStyle,
              '& path': {
                stroke: theme.palette.primary.dark,
              },
            }}
          >
            <Box sx={{ display: 'flex', transform: 'scale(0.8)', alignItems: 'center' }}>
              <CustomIcon name={IconNames.settings} sx={{ color: theme.palette.primary.dark }} />
            </Box>
            <Typography sx={{ color: theme.palette.primary.dark, fontWeight: '500' }}>Edit</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default OverviewSection;
