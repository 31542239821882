import DashSpeedometer from 'apps/webapp/src/views/dashboard/components/DashSpeedometer';
import CustomToolTip from 'apps/webapp/src/@core/components/custom-tooltip';
import { CUSTOMER_ENGAGEMENT_TYPE } from '@finance-ops/types';
import { Box, Typography, useTheme } from '@mui/material';
import { RootState } from 'apps/webapp/src/store';
import { useSelector } from 'react-redux';
import { useState } from 'react';

import CommonFooter from './CommonFooter';
import CustomLegend from './CustomLegend';
import Heading from './Heading';
import Loader from 'apps/webapp/src/@core/components/loader';

const CustomerEngagement = () => {
  const theme = useTheme();
  const [selectedLabel, setSelectedLabel] = useState('');
  const loading = useSelector((state: RootState) => state.dashboard.loading);

  const {
    poorEngagementScoreCount,
    mediumEngagementScoreCount,
    highEngagementScoreCount,
    assignedCustomersCount,
    naEngagementScoreCount,
  } = useSelector((state: RootState) => state.dashboard.aggregation);

  const data: {
    key: string;
    value: number;
  }[] = [
    {
      key: CUSTOMER_ENGAGEMENT_TYPE.POOR,
      value: poorEngagementScoreCount,
    },
    {
      key: CUSTOMER_ENGAGEMENT_TYPE.MEDIUM,
      value: mediumEngagementScoreCount,
    },
    {
      key: CUSTOMER_ENGAGEMENT_TYPE.HIGH,
      value: highEngagementScoreCount,
    },
    // {
    //   key: CUSTOMER_ENGAGEMENT_TYPE.NOT_APPLICABLE,
    //   value: naEngagementScoreCount ?? 0,
    // },
  ];

  const dashData = data.map(each => {
    return { label: each.key, value: each.value };
  });

  const legendData = data.map(each => {
    return { text: each.key, value: each?.value?.toString() ?? 'NA' };
  });

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        <Heading text='Customer Engagement' />
        <CustomToolTip text='Customer Engagement' />
      </Box>
      {loading ? (
        <Loader height='230px' />
      ) : (
        <>
          <Box sx={{ maxWidth: 380, mx: 'auto', textAlign: 'center' }}>
            <Box sx={{ position: 'relative' }}>
              <DashSpeedometer
                displayData={dashData}
                colors={['#6461F3', '#C0AAFF', '#263238', '#CFD8DC']}
                setSelectedLabel={(newLabel: string) =>
                  setSelectedLabel(old => {
                    return old === newLabel ? '' : newLabel;
                  })
                }
              />
              <Box
                sx={{
                  position: 'absolute',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',

                  top: '50%',
                  transform: 'translateX(50%)',
                  right: '50%',
                }}
              >
                <Typography sx={{ color: theme.palette.grey[500] }}>Customers</Typography>
                <Typography
                  sx={{ fontSize: '24px', fontWeight: '700', color: theme.palette.customColors.headingPrimary }}
                >
                  {assignedCustomersCount}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ pt: '6px', margin: '0 auto', maxWidth: 'fit-content' }}>
            <CustomLegend data={legendData} />
          </Box>
        </>
      )}
      <CommonFooter
        url='customers'
        filters={{ engagement: selectedLabel }}
        displayViewMore={selectedLabel ? true : false}
      />
    </Box>
  );
};

export default CustomerEngagement;
