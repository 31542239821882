import CustomToolTip from 'apps/webapp/src/@core/components/custom-tooltip';
import { formatUSD } from 'apps/webapp/src/@core/utils/format';
import { Box, Typography, useTheme } from '@mui/material';
import { RootState } from 'apps/webapp/src/store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import CommonFooter from './CommonFooter';
import Heading from './Heading';
import Loader from 'apps/webapp/src/@core/components/loader';

const CollectMatrix = () => {
  const collectabilityMatrix = useSelector((state: RootState) => state.dashboard.aggregation.collectabilityMatrix);
  const loading = useSelector((state: RootState) => state.dashboard.loading);

  const [collectabilityMatrixData, setCollectabilityMatrixData] = useState({
    lowLow: { count: 0, amount: 0 },
    lowHigh: { count: 0, amount: 0 },
    highLow: { count: 0, amount: 0 },
    highHigh: { count: 0, amount: 0 },
  });

  const [selectedComplexity, setSelectedComplexity] = useState('');
  const [selectedValue, setSelectedValue] = useState('');

  useEffect(() => {
    const collectabilityMatrixData = {
      lowLow: { count: 0, amount: 0 },
      lowHigh: { count: 0, amount: 0 },
      highLow: { count: 0, amount: 0 },
      highHigh: { count: 0, amount: 0 },
    };

    collectabilityMatrix.forEach(item => {
      if (item._id) {
        if (item._id.difficulty === 'Low' && item._id.amount === 'Low') {
          collectabilityMatrixData.lowLow.count = item.count;
          collectabilityMatrixData.lowLow.amount = item.amount;
        }
        if (item._id.difficulty === 'Low' && item._id.amount === 'High') {
          collectabilityMatrixData.lowHigh.count = item.count;
          collectabilityMatrixData.lowHigh.amount = item.amount;
        }
        if (item._id.difficulty === 'High' && item._id.amount === 'Low') {
          collectabilityMatrixData.highLow.count = item.count;
          collectabilityMatrixData.highLow.amount = item.amount;
        }
        if (item._id.difficulty === 'High' && item._id.amount === 'High') {
          collectabilityMatrixData.highHigh.count = item.count;
          collectabilityMatrixData.highHigh.amount = item.amount;
        }
      }
    });
    setCollectabilityMatrixData(collectabilityMatrixData);
  }, [collectabilityMatrix]);

  const theme = useTheme();
  const data = [
    {
      amount: formatUSD(collectabilityMatrixData.lowHigh.amount),
      customers: collectabilityMatrixData.lowHigh.count,
      color: theme.palette.common.white,
      background: '#C0AAFF',
      complexity: 'Low',
      value: 'High',
    },
    {
      amount: formatUSD(collectabilityMatrixData.highHigh.amount),
      customers: collectabilityMatrixData.highHigh.count,
      color: theme.palette.common.white,
      background: '#263238',
      complexity: 'High',
      value: 'High',
    },
    {
      amount: formatUSD(collectabilityMatrixData.lowLow.amount),
      customers: collectabilityMatrixData.lowLow.count,
      color: theme.palette.common.black,
      background: '#CFD8DC',
      complexity: 'Low',
      value: 'Low',
    },
    {
      amount: formatUSD(collectabilityMatrixData.highLow.amount),
      customers: collectabilityMatrixData.highLow.count,
      color: theme.palette.common.white,
      background: theme.palette.primary.main,
      complexity: 'High',
      value: 'Low',
    },
  ];
  return (
    <Box sx={{ width: '100%', minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ pb: '32px', display: 'flex', alignItems: 'center', gap: '5px' }}>
        <Heading text='Collectability Matrix' />
        <CustomToolTip text='Collectability Matrix' />
      </Box>
      {loading ? (
        <Loader height={'350px'} />
      ) : (
        <Box
          sx={{
            width: '80%',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '16px',
            margin: 'auto',
            justifyContent: 'center',
            position: 'relative',
            '&::after': {
              position: 'absolute',
              content: '"Difficulty"',
              bottom: '-40px',
              color: theme.palette.grey[500],
            },
            '&::before': {
              position: 'absolute',
              content: '"Amount"',
              left: '-40px',
              color: theme.palette.grey[500],
              rotate: '270deg',
              top: '50%',
              transform: 'translateX(10px)',
            },
          }}
        >
          {data.map((item, index) => {
            return (
              <Box
                onClick={() => {
                  if (selectedComplexity === item.complexity && selectedValue === item.value) {
                    setSelectedComplexity('');
                    setSelectedValue('');
                  } else {
                    setSelectedComplexity(item.complexity);
                    setSelectedValue(item.value);
                  }
                }}
                key={item.complexity + item.value + index}
                sx={{
                  background:
                    selectedComplexity === item.complexity && selectedValue === item.value
                      ? 'lightgray'
                      : item.background,
                  height: 0,
                  width: '40.5%',
                  paddingTop: '40.5%',
                  position: 'relative',
                  borderRadius: '6px',
                  cursor: 'pointer',
                  border:
                    selectedComplexity === item.complexity && selectedValue === item.value ? '1px solid black' : 'none',
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    left: '0',
                    bottom: '0',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Typography sx={{ color: item.color, fontWeight: 700 }}>{item.amount}</Typography>
                  <Typography sx={{ color: item.color, fontWeight: 700 }}>{item.customers} customers</Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      )}
      <CommonFooter
        url='customers'
        filters={{ eocValue: selectedValue, eocComplexity: selectedComplexity }}
        displayViewMore={selectedValue && selectedComplexity ? true : false}
      />
    </Box>
  );
};

export default CollectMatrix;
