import { Box } from '@mui/material';

interface Item {
  value: number;
  color?: string;
  text?: string;
}

interface SingleBarGraph {
  data: Item[];
  setSelectedBar?: (value: string) => void;
  selectedBar?: string;
}

const getColor = (index: number) => {
  switch (index) {
    case 0:
      return '#6461F3';
    case 1:
      return '#C0AAFF';
    case 2:
      return '#263238';
    case 3:
      return '#CFD8DC';
    default:
      return '#6461F3';
  }
};

const SingleBarGraph = ({ data, setSelectedBar, selectedBar }: SingleBarGraph) => {
  const total = data?.reduce((acc, item) => acc + item.value, 0);
  return (
    <Box sx={{ width: '100%', display: 'flex', gap: '4px' }}>
      {data?.map((item, index) => {
        return (
          <Box
            onClick={() =>
              setSelectedBar && (item.text === selectedBar ? setSelectedBar('') : setSelectedBar(item.text || ''))
            }
            key={item.text || index}
            sx={{
              width: `${(item.value / total) * 100}%`,
              height: '25px',
              borderRadius: '4px',
              background: item.color || getColor(index),
              cursor: 'pointer',
              transition: '0.2s',
              '&:focus': {
                opacity: '0.75',
              },
              border: selectedBar === item.text ? '2.5px solid lightgray' : null,
            }}
          ></Box>
        );
      })}
    </Box>
  );
};

export default SingleBarGraph;
