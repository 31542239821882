import CustomSummaryItem from 'apps/webapp/src/@core/components/custom-summary/CustomSummaryItem';
import { Box, Grid, Skeleton, Tooltip, Typography, useTheme } from '@mui/material';
import { RootState } from 'apps/webapp/src/store';
import { useSelector } from 'react-redux';
import { overallSummaryAggregationType } from '@finance-ops/types';
import ChangeChip from './ChangeChip';
import { formatUSD } from 'apps/webapp/src/@core/utils/format';
import { useRouter } from 'next/router';
import { differenceInDays, startOfMonth } from 'date-fns';
import { taskModeFromUiMode } from '../utils/taskModeFromUiMode';
import { useAuth } from 'apps/webapp/src/hooks/useAuth';

{
  /* DO NOT REMOVE THIS COMMENT */
}
{
  /* <CommonFooter url='payments' filters={{ hasPaymentPlans: 'Yes' }} /> */
}

const AccountSummary = () => {
  const theme = useTheme();
  const router = useRouter();
  const { user } = useAuth();
  const agg = useSelector((state: RootState) => state.dashboard.aggregation);
  const aggOld = useSelector((state: RootState) => state.dashboard.aggregation30DaysBack);
  // const { cei } = useSelector((state: RootState) => state.dashboard.formulaCards);
  const uiMode = useSelector((state: RootState) => state.dashboard.uiMode);
  const overview = useSelector((state: RootState) => state.dashboard.overview.data);
  const loading = useSelector((state: RootState) => state.dashboard.overview.loading);
  interface CustomSummaryItemInterface {
    heading: string;
    currentValue: number;
    previousValue?: number;
    minWidth?: string;
    type?: 'currency' | 'percentage';
    url?: string;
  }

  const recoveryRate = parseFloat(((agg.collectedAmount / (agg.openAmount + agg.collectedAmount)) * 100).toFixed(2));
  const recoveryOld = parseFloat(
    ((aggOld.collectedAmount / (aggOld.openAmount + aggOld.collectedAmount)) * 100).toFixed(2),
  );

  const data: CustomSummaryItemInterface[] = [
    {
      heading: 'Recovery rate',
      currentValue: recoveryRate || 0,
      previousValue: recoveryOld || 0,
      type: 'percentage',
    },
    // {
    //   heading: 'CEI',
    //   currentValue: cei.current || 0,
    //   previousValue: 0,
    // },
    {
      heading: 'Payment Plans',
      currentValue: agg.paymentPlans || 0,
      previousValue: aggOld.paymentPlans || 0,
    },
  ];

  const getLabel = (key: keyof overallSummaryAggregationType) => {
    switch (key) {
      case 'totalDue':
        return 'Total Outstanding';
      case 'currentDue':
        return 'Current Due';
      case 'overdue':
        return 'Overdue';
      case 'collections':
        return 'Collections';
      default:
        return '';
    }
  };

  const handleNavigation = (key: string) => {
    const taskMode = taskModeFromUiMode(user?.role?.isInternal, uiMode);
    switch (key) {
      case 'collectionsThisMonth':
        {
          let filterPayload: any = {
            page: 'transactions',
            paymentsFrom: differenceInDays(new Date(), startOfMonth(new Date())),
          };
          if (taskMode) {
            filterPayload = { ...filterPayload, mode: taskMode };
          }
          router.push({
            pathname: '/payments',
            query: filterPayload,
          });
        }

        break;
      default:
        break;
    }
  };

  const cardData = Object.keys(overview).map(eachKey => {
    const key = eachKey as keyof overallSummaryAggregationType;
    return {
      heading: key,
      ...overview[key],
      previousValue: aggOld?.dashboardMetrics?.[key]?.amount ?? 0,
    };
  });

  return (
    <>
      <Grid container spacing={1}>
        {cardData.map((data, index) => (
          <Grid item xs={6} sm={6} md={4} lg={4} key={index}>
            <Grid sx={{ px: 2, borderLeft: '2px solid darkgray' }} container gap={6}>
              <Grid item sx={{ my: 2 }} md={5.5}>
                <Typography sx={{ px: 1.5, pb: 0, fontSize: '1.2rem', fontWeight: 600 }}>
                  {getLabel(data.heading)}
                </Typography>
                {loading ? (
                  <Skeleton variant='text' sx={{ fontSize: '1.8rem', width: '60%' }} />
                ) : (
                  <Tooltip
                    title={
                      data.heading === 'collections'
                        ? 'Displays so far this month. Takes you to transactions page'
                        : data.heading
                    }
                  >
                    <Typography
                      sx={{
                        py: 0,
                        px: 1,
                        fontSize: '1.8rem',
                        fontWeight: 500,
                        '&:hover':
                          data.heading === 'collections'
                            ? {
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                fontWeight: 600,
                                color: theme.palette.primary.main,
                              }
                            : {},
                      }}
                      onClick={() => handleNavigation('collectionsThisMonth')}
                    >
                      {formatUSD(data.amount)}
                    </Typography>
                  </Tooltip>
                )}
                <Typography sx={{ pl: 1 }}>{data.heading === 'collections' ? `This Month` : `Till Date`}</Typography>
                <Box sx={{ px: 1, mt: 5 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ChangeChip old={data.previousValue} now={data.amount} />
                    <Typography sx={{ fontSize: '1rem', ml: 4 }}>
                      {data.previousValue ? formatUSD(data.previousValue) : ''}
                    </Typography>
                  </Box>
                  <Typography>Since Last Month</Typography>
                </Box>
              </Grid>
              <Grid item sx={{ my: 1.5 }}>
                <Box sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: '0.8rem', fontWeight: 600 }}>Total Customers</Typography>
                  {loading ? (
                    <Skeleton variant='text' sx={{ fontSize: '1rem', width: '60%' }} />
                  ) : (
                    <Typography sx={{ fontWeight: 500, fontSize: '1rem' }}>{data.customersCount}</Typography>
                  )}
                </Box>
                <Box>
                  <Typography sx={{ fontSize: '0.8rem', fontWeight: 600 }}>Total Invoices</Typography>
                  {loading ? (
                    <Skeleton variant='text' sx={{ fontSize: '1rem', width: '60%' }} />
                  ) : (
                    <Typography sx={{ fontWeight: 500, fontSize: '1rem' }}>{data.invoicesCount}</Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        ))}
        <Grid item xs={6} sm={6} md={5} lg={4}>
          <Grid sx={{ px: 2, borderLeft: '2px solid darkgray' }} container>
            <Grid item sx={{ mb: 4 }} md={5.5}>
              <Tooltip title='Average Delinquent Days'>
                <Typography
                  sx={{
                    px: 1.5,
                    pb: 0,
                    fontSize: '1.2rem',
                    fontWeight: 600,
                  }}
                >
                  ADD
                </Typography>
              </Tooltip>
              {loading ? (
                <Skeleton variant='text' sx={{ fontSize: '1.8rem', width: '60%' }} />
              ) : (
                <Typography sx={{ pt: 0, px: 1, fontSize: '1.8rem', fontWeight: 500 }}>
                  {agg.averageDelinquentDays} days
                </Typography>
              )}
              <Typography sx={{ p: 0.5, pl: 1 }}>This Month</Typography>
              <Box sx={{ px: 1, mt: 5 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ChangeChip old={aggOld.averageDelinquentDays} now={agg.averageDelinquentDays} />
                  <Typography sx={{ fontSize: '1rem', ml: 4 }}>
                    {aggOld.averageDelinquentDays ? aggOld.averageDelinquentDays + ' days' : ''}
                  </Typography>
                </Box>
                <Typography>Since Last Month</Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ mb: 2 }}>
                <Typography sx={{ fontSize: '0.8rem', fontWeight: 600 }}>Total P2Ps</Typography>
                {loading ? (
                  <Skeleton variant='text' sx={{ fontSize: '1rem', width: '60%' }} />
                ) : (
                  <Typography sx={{ fontWeight: 500, fontSize: '1rem' }}>{formatUSD(agg.totalP2PAmount)}</Typography>
                )}
              </Box>
              <Box>
                <Typography sx={{ fontSize: '0.8rem', fontWeight: 600 }}>Total Others</Typography>
                {loading ? (
                  <Skeleton variant='text' sx={{ fontSize: '1rem', width: '60%' }} />
                ) : (
                  <Typography sx={{ fontWeight: 500, fontSize: '1rem' }}>
                    {formatUSD(agg.totalDisputeAmount)}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6} md={5} lg={4} sx={{ my: 2 }}>
          <Grid sx={{ px: 2, borderLeft: '2px solid darkgray' }} container>
            <Grid item sx={{ mb: 4 }} md={5.5}>
              <Typography sx={{ px: 1.5, pb: 0, fontSize: '1.2rem', fontWeight: 600 }}>
                Predicted Collections
              </Typography>
              {loading ? (
                <Skeleton variant='text' sx={{ fontSize: '1.8rem', width: '60%' }} />
              ) : (
                <Typography sx={{ pt: 0, px: 1, fontSize: '1.8rem', fontWeight: 500 }}>
                  {formatUSD(agg.predictedCollections ?? 0)}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>

        {data.map((data: CustomSummaryItemInterface, index) => {
          return (
            <Grid item xs={12} sm={4} md={4} lg={4} key={index}>
              <CustomSummaryItem key={index} {...data} />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default AccountSummary;
