import ReactApexCharts from 'apps/webapp/src/@core/components/react-apexcharts';
import { differenceInDays, format, isSameDay } from 'date-fns';
import { Box, useTheme } from '@mui/material';

const DelinquencyPerformanceGraph = ({ delinquency }: { delinquency: { x: Date | string; y: number }[] }) => {
  const theme = useTheme();

  const DOWNSAMPLED_POINTS = 8;
  const DISPLAY_DATES = DOWNSAMPLED_POINTS * (70 / 100);

  const downsampleData = (data: { x: Date | string; y: number }[], maxPoints: number) => {
    if (data.length <= maxPoints) return data;

    const step = Math.floor((data.length - 2) / (maxPoints - 2));
    const downsampledData = [data[0]];

    for (let i = 1; i < maxPoints - 1; i++) {
      const index = i * step;
      if (index < data.length - 1) {
        downsampledData.push(data[index]);
      }
    }

    downsampledData.push(data[data.length - 1]); // Keep the last data point

    return downsampledData;
  };
  const limitedData = downsampleData(delinquency, DOWNSAMPLED_POINTS);

  const data = [
    {
      name: 'Delinquency',
      data: limitedData,
    },
  ];

  return (
    <Box style={{ width: '100%', height: '230px' }}>
      <ReactApexCharts
        css={{
          '& .apexcharts-svg': {
            backgroundColor: `${theme.palette.background.paper} !important`,
          },
          '& .apexcharts-gridline': {
            // stroke: `${theme.palette.background.default} !important`
          },
          '& .apexcharts-toolbar': {
            display: 'none',
          },
          '& .apexcharts-tooltip .apexcharts-tooltip-title': {
            background: `${theme.palette.primary.main}!important`,
            borderColor: `${theme.palette.primary.main} !important`,
            color: `${theme.palette.common.white} !important`,
          },
          '& .apexcharts-xaxistooltip': {
            display: 'none !important',
          },
          '& .apexcharts-tooltip': {
            background: '#DCDCFF !important',
            marginTop: '-40px',
          },
          '& .apexcharts-legend': {
            left: 'auto !important',
          },
          '& .apexcharts-legend-marker': {
            borderRadius: '4px !important',
          },
        }}
        series={data.map((each, idx) => {
          return {
            name: each.name,
            data: each.data,
            color: idx === 0 ? theme.palette.primary.main : idx === 1 ? theme.palette.customColors.green : '#C5C5C5',
          };
        })}
        options={{
          chart: {
            id: 'basic-bar',
            type: 'line',
          },
          xaxis: {
            type: 'datetime',
            labels: {
              formatter(value) {
                const date = new Date(value);
                const today = new Date();
                if (isSameDay(date, today)) {
                  return format(date, 'hh:mm a');
                } else if (differenceInDays(today, date) <= 7) {
                  return format(date, 'MMM dd, hh a');
                } else {
                  return format(date, 'MMM dd, yyyy');
                }
              },
            },
            tickAmount: DISPLAY_DATES,
          },
          yaxis: {
            min: 0,
            max: 100,
          },
          markers: {
            size: 2,
          },
          stroke: {
            curve: 'smooth',
            width: 2,
            colors: [theme.palette.primary.main, '#E25CC4', '#EBB447', theme.palette.customColors.green],
          },
          fill: {
            type: 'solid',
          },
          grid: {
            show: true,
            borderColor: theme.palette.grey[300],
            strokeDashArray: 10,
            position: 'back',
            xaxis: {
              lines: {
                show: false,
              },
            },
            yaxis: {
              lines: {
                show: true,
              },
            },
          },
          legend: {
            markers: {
              // fillColors: [theme.palette.primary.main, '#E25CC4'],
            },
            position: 'top',
          },
          tooltip: {
            style: {
              fontSize: '10px',
            },
            marker: {
              show: false,
            },
            x: {
              show: true,
              formatter: value => {
                const date = new Date(value);
                return format(date, 'MMM dd, yyyy HH:mm a');
              },
            },
          },
        }}
        type='line'
        width={'100%'}
        height='100%'
      />
    </Box>
  );
};

export default DelinquencyPerformanceGraph;
